import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutUsPage = () => (
  <Layout>
    <SEO title="About us" />
    <h1 className="title">About us</h1>
    <hr />
    <section className="section">
      <div className="container">
        <h1 className="title">1. Special Operations</h1>
        <h2 className="subtitle">We're independent, small, fast and Agile.</h2>
        <p>
          We believe that these are strengths that your can rely on to propel your business. Agents of change do not
          come in herd.
        </p>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">2. Communicative</h1>
        <h2 className="subtitle">Clear, concise and appropriate.</h2>
        <p>
          No one is left behind. From company wide announcements to highly targeted individuals, we pick the right
          communication channels for the highest impact.
        </p>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">3. Focus</h1>
        <h2 className="subtitle">Laser focus.</h2>
        <p>
          Scope boundaries. Our projects <em>do not slip on the timeline</em>. Our mantra is "
          <em>no code is better than any code</em>".
        </p>
      </div>
    </section>
  </Layout>
);

export default AboutUsPage;
